.navbar {
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(41, 23, 112, 0.2);
}

.navbar-brand {
  color: #D9261C !important;
  font-family: "Bahnschrift";
}

.nav-link {
  color: #D9261C !important;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  border-bottom: 1px solid #291770 !important;
  font-family: "Bahnschrift";
}

.nav-link:hover {
  background-color: transparent;
  background-color: #291770 !important;
  border-bottom: 1px solid #ffffff !important;
  color: #ffffff !important;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.navbar-toggler {
  border: 1px solid #D9261C !important;
}

.navbar-toggler:hover {
  border: 1px solid #291770 !important;
}


.navbar-toggler-icon {
  background-color: #ffffff !important;
  background-image: url(
      "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(217, 38, 28, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler-icon:hover {
  background-color: #ffffff !important;
  background-image: url(
      "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(41, 23, 112, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-collapse .nav-link {
  text-align: center;
}


