.prefooter {
    background-color: #F7E7B8 !important;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 0 0.75rem 0 rgba(82,33,89, 0.2);
}
.info {
    display: flex;
    flex-direction: column;
    font-family: "Constantia";
    color: #291770;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.logo {
    height: 15rem;
}