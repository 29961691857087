.card {
    border: 0.1rem solid #80D8AB !important;
    padding-top: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(41, 23, 112, 0.2);
}

.cardtitle {
    text-align: center;
    color: #291770;
    font-family: "Bahnschrift";
    text-transform: uppercase;
}

.cardname {
    text-align: center;
    color: #BA5624 !important;
    font-family: "Constantia";
}

.carddescription {
    color: #291770;
    font-family: "Constantia";
    font-size: 1.1rem;
}

.office {
    display: flex;
    max-width: 80%;
    margin: 0 auto;
}

.home {
    display: flex;
    height: 10rem;
    margin: 0 auto;
    padding-bottom: 1rem;
}