.timelinetitle {
    font-family: "Bahnschrift";
    font-size: 2rem !important;
    color: #291770;
}

.timelinedate {
    color: #BA5624;
    font-family: "Bahnschrift";
    font-size: 1.5rem !important;
}

.timelinedescription {
    font-family: "Constantia";
    color: #2176FF;
    font-size: 1rem !important;
    white-space: pre-line;
}

.timelinelocation {
    font-family: "Constantia";
    color: #291770;
    font-size: 1rem !important;
    white-space: pre-line;
}