.footer {
    background-color: #D9261C;
    font-family: "Bahnschrift";
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 0 1rem 0 rgba(82,33,89, 0.2);
}

.footer-icons {
    text-align: center;
    color: #ffffff !important;
} 

.footer-links {
    text-align: center;
    color: #ffffff !important;
    margin-right: 1rem;
    text-decoration: none;
    font-size: 0.8rem;
}

.footer-links:hover {
    text-align: center !important;
    color: #ffffff !important;
    text-decoration: underline;
}

.footer-inline {
    text-align: center;   
}

.ds-logo {
    margin-top: 0.5rem;
    height: 3rem;
}
