.text {
    color: #291770;
    text-align: left;
    font-family: "Constantia";
    font-size: 1.1rem;
    white-space: pre-line; 
}

.description {
    text-align: center !important;
    color: #522159 !important;
    font-family: "TangerineBold";
    font-size: 3rem;
    white-space: pre-line; 
}

.verse {
    text-align: center !important;
    color: #522159 !important;
    font-family: "TangerineBold";
    font-size: 3rem;
}

.center {
    text-align: center !important;
    font-size: 1.1rem;
}

.flex-row {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 1rem;
}

.bold {
    font-weight: bold;
}