.formgroup {
    color: #BA5624 !important;
    font-family: "Constantia";
}

.form-control {
    border-radius: 1rem;
    border: #2318a4 solid thin !important;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(41, 23, 112, 0.1);
}

.form-control:focus {
    border-radius: 1rem;
    color: #2318a4 !important;
}
.submit-button {
    background-color: #fde4af;
    color: #2318a4 !important;
    border: #2318a4 solid thin !important;
    margin: 0 auto;
    display: block;
    justify-content: center;
    font-family: "Bahnschrift" !important;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(41, 23, 112, 0.2);
    
}
  
.submit-button:hover {
    color: #ffffff !important;
    background-color: #2318a4 !important;
}

.rightpanelcontents {
    display: flex;
    flex-direction: column;
    align-items: right;
    padding-top: 15%;
}

.welcome {
    border-top: 1px solid #2318a4;
    text-align: right !important;
    color: #291770 !important;
    font-family: "TangerineBold";
    font-size: 10vw;
    margin-bottom: -5%;
}

.churchname {
    border-bottom: 1px solid #2318a4;
    text-align: right !important;
    color: #291770 !important;
    font-family: "Bahnschrift";
    font-size: 3.25vw;
    padding-top: 5%;
    padding-bottom: 5%;
}

.timelinecards {
    align-self: flex-start;
}

.horizontalline {
    border-top: 1px solid #291770;
    margin-left: 1.5rem;
}

li { 
    color: #291770;
    font-family: "Constantia";
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.gallery-tabs {
    border-bottom: none !important;
}

.nav-tabs {
    color: #291770 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.nav-link.active {
    background-color: #291770 !important;
    color: #ffffff !important;
}
  
  /* .nav-tabs .nav-link.active {
    background-color: #291770 !important;
    color: #ffffff;
  }
   */