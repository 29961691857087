.modaltitle {
    font-family: "Bahnschrift";
    color: #2318a4;
}

.modalbody {
    font-family: "Constantia" !important;
    color: #2318a4;
}

.close {
    background-color: #ffffff;
    color: #2318a4;
    border: 0.1rem solid #2318a4;
    border-radius: 0.5rem;
    font-family: "Bahnschrift";
    margin: 0 auto !important;
    display: block !important;
    justify-content: center !important;
}

.close:hover {
    background-color: #2318a4;
    color: #ffffff;
}