.subtitle {
    text-align: center;
    color: #2176FF;
    font-family: "Bahnschrift";
    text-transform: uppercase;
    padding-bottom: 1rem;
}

.subtitle-left {
    text-align: left;
    color: #2176FF;
    font-family: "Bahnschrift";
    text-transform: uppercase;
    padding-top: 1rem;
    padding-bottom: 1rem;
}