@font-face {
  font-family: "TangerineBold";
  src: local("Tangeringe"), url("../src/fonts/Tangerine-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Bahnschrift";
  src: local("Bahnschrift"), url("../src/fonts/Bahnschrtift.TTF") format("truetype");
}

@font-face {
  font-family: "Constantia";
  src: local("Constantia"), url("../src/fonts/Constantia.ttf") format("truetype");
}


body {
  padding-top: 5rem;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
